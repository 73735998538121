import React from "react"

export const HeaderComponent: React.FC = () => {
    return (
    <>
        <div>
            Header
        </div>
    </>
    )
}